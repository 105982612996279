import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { navigate } from 'gatsby';
import ResetPasswordForm from './style';

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState(null);
  const [isValidToken, setIsValidToken] = useState(false);

  useEffect(() => {
    // Extract the token from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('token');

    if (!tokenFromUrl) {
      // If the token is not found, redirect to the reset failed page
      navigate('/resetfailed');
    } else {
      setToken(tokenFromUrl);

      // Validate the token with a GET request
      fetch(`https://api.zenzoapp.com/v1/resetPassword/${tokenFromUrl}`)
        .then(response => {
          if (response.status === 200) {
            setIsValidToken(true);
          } else {
            navigate('/resetfailed');
          }
        })
        .catch(() => {
          navigate('/resetfailed');
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      const response = await fetch('https://api.zenzoapp.com/v1/resetPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          newPassword: newPassword,
        }),
      });

      if (response.status === 200) {
        // Redirect to success page after successful password reset
        navigate('/resetsuccess');
      } else {
        // Handle errors or invalid response
        navigate('/resetfailed');
      }
    } catch (error) {
      // Handle network errors or other unexpected errors
      navigate('/resetfailed');
    }
  };

  if (!isValidToken) {
    // Optionally render a loading spinner or a blank page while validating the token
    return <div>Loading...</div>;
  }

  return (
    <ResetPasswordForm backgroundColor="#f3f4f6">
      <Container className="position-static">
        <Row className="align-items-center justify-content-center position-static">
          <Col xs="12" className="col-xxl-6 col-xl-6 col-lg-7 col-md-9">
            <ResetPasswordForm.Box plXXL="60px">
              <ResetPasswordForm.Title as="h2">Reset Your Zenzo Password</ResetPasswordForm.Title>
              <ResetPasswordForm.Text as="p">Enter a new strong password.</ResetPasswordForm.Text>
              <ResetPasswordForm.FromSection>
                <form onSubmit={handleSubmit}>
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Your New Password"
                      id="floatinginput3"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <label htmlFor="floatinginput3">Your Password</label>
                  </div>
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Confirm Your New Password"
                      id="floatinginput4"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <label htmlFor="floatinginput4">Confirm Your Password</label>
                  </div>
                  <ResetPasswordForm.FormButton className="btn-primary mt-2" type="submit">
                    Reset Password
                  </ResetPasswordForm.FormButton>
                </form>
              </ResetPasswordForm.FromSection>
            </ResetPasswordForm.Box>
          </Col>
        </Row>
      </Container>
    </ResetPasswordForm>
  );
}
